import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SharedService } from '@app/shared/services/shared.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  isCustomAuth = false;

  constructor(
    private authenticationService: SharedService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        console.log('request:::::', request);
        console.log('err:::::', err);

        if (request.url.includes('isCustomAuth=true')) {
          this.isCustomAuth = true;
        }

        if (this.isCustomAuth) {
          // redirect on unauthorized page
          this.router
            .navigate(['unauthorized'])
            .then((_) => console.log('redirect to unauthorized'));
        } else if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
          location.reload();
        }

        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
