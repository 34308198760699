import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  data: any = {}

  constructor() {
  }

  set(key, value) {
    this.data[key] = value
  }

  get(key) {
    return this.data[key]
  }

  remove(key) {
    delete this.data[key]
  }

  getToken() {
    return this.data.accessToken
  }

}
