import { Injectable } from '@angular/core';
import { SharedService } from '../shared.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  PermissionsMap = {
    OPERATION: {
      DASHBOARD: { read: true, write: false },
      CHARGING_SESSION: { read: true, write: false },
      CHARGING_STATION: { read: true, write: true },
      CPO_MANAGEMENT: { read: true, write: true },
      BILL_PAYMENT: { read: true, write: false },
      REPORTS: { read: true, write: false },
      CUSTOMER: { read: true, write: true },
      USER_MANAGEMENT: { read: false, write: false },
      SUPPORT: { read: true, write: true },
      SETUP: { read: true, write: false },
    },
    CUSTOMER_SUPPORT: {
      DASHBOARD: { read: false, write: false },
      CHARGING_SESSION: { read: true, write: false },
      CHARGING_STATION: { read: true, write: false },
      CPO_MANAGEMENT: { read: true, write: false },
      BILL_PAYMENT: { read: true, write: true },
      REPORTS: { read: false, write: false },
      CUSTOMER: { read: true, write: false },
      USER_MANAGEMENT: { read: false, write: false },
      SUPPORT: { read: true, write: true },
      SETUP: { read: false, write: false },
    },
    ADMIN: {
      DASHBOARD: { read: true, write: true },
      CHARGING_SESSION: { read: true, write: true },
      CHARGING_STATION: { read: true, write: true },
      CPO_MANAGEMENT: { read: true, write: true },
      BILL_PAYMENT: { read: true, write: true },
      REPORTS: { read: true, write: true },
      CUSTOMER: { read: true, write: true },
      USER_MANAGEMENT: { read: true, write: true },
      SUPPORT: { read: true, write: true },
      SETUP: { read: true, write: true },
    },
    USER: {
      DASHBOARD: { read: true, write: false },
      CHARGING_SESSION: { read: true, write: false },
      CHARGING_STATION: { read: true, write: true },
      CPO_MANAGEMENT: { read: true, write: true },
      BILL_PAYMENT: { read: true, write: false },
      REPORTS: { read: true, write: false },
      CUSTOMER: { read: true, write: true },
      USER_MANAGEMENT: { read: false, write: false },
      SUPPORT: { read: true, write: true },
      SETUP: { read: true, write: false },
    },
  }

  constructor(
    private SharedService: SharedService
  ) { }

  hasReadAccess(module: any) {

    const userRole = this.SharedService.getUserRole()
    const permissions = this.PermissionsMap[userRole][module]

    if (permissions) {
      return permissions.read
    }

    return true

  }

  hasWriteAccess(module: any) {

    const userRole = this.SharedService.getUserRole()
    const permissions = this.PermissionsMap[userRole][module]

    if (permissions) {
      return permissions.write
    }

    return true

  }

}
