import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { CPOInfo } from '@app/_models/cop-info';
import { Observable, of, throwError } from 'rxjs';
import { TokenService } from './token.service';
import { StoreService } from './store.service';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';

const OAUTH_CLIENT = 'mobile';
const OAUTH_SECRET = 'pin';
const API_URL = environment.apiUrl;
//const API_URL = 'http://localhost:8765/';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: 'Basic ' + btoa(OAUTH_CLIENT + ':' + OAUTH_SECRET),
  }),
};

const ALERT_OPTIONS = {
  autoClose: true,
  keepAfterRouteChange: true,
};

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public redirectUrl: string = '';
  public isExpanded: boolean = false;
  isFleetPortal = window.location.hostname.indexOf('fleet') > 0;
  fleetCompanyId = '';
  fleetId = '';

  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService,
    private storeService: StoreService
  ) {}

  getIsFleetPortal() {
    return this.isFleetPortal;
  }

  getFleetId() {
    return this.fleetId;
  }

  getFleetCompanyId() {
    return this.fleetCompanyId;
  }

  setIsExpand(value) {
    this.isExpanded = value;
  }

  logout(): void {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    localStorage.clear();
  }

  public static getApiUrl(): string {
    return API_URL;
  }
  // public static handleError(error: HttpErrorResponse): any {
  //   console.log(error)
  //   if (error.error instanceof ErrorEvent) {
  //     console.error('An error occurred:', error.error.message);
  //   } else {
  //     console.error(
  //       `Backend returned code ${error.status}, ` +
  //       `body was: ${error.error}`);
  //   }
  //   return throwError(
  //     'Something bad happened; please try again later.');
  // }

  private static log(message: string): any {
    console.log(message);
  }

  login(username: string, password: string): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = {
      username: username,
      password: password,
      user_type: this.isFleetPortal ? 'FLEET' : 'USER',
    };

    return this.httpClient
      .post<any>(API_URL + 'api/user/oauth/token/username', body)
      .pipe(
        tap((res) => {
          this.storeService.set('accessToken', res.access_token);
          this.storeService.set('refreshToken', res.refresh_token);
        })
        // catchError(SharedService.handleError)
      );
  }

  setTokens() {
    let accessToken = this.storeService.get('accessToken');
    let refreshToken = this.storeService.get('refreshToken');
    this.tokenService.saveToken(accessToken);
    this.tokenService.saveRefreshToken(refreshToken);
    this.storeService.remove('accessToken');
    this.storeService.remove('refreshToken');
  }

  refreshToken(refreshData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = new HttpParams()
      .set('refresh_token', refreshData.refresh_token)
      .set('grant_type', 'refresh_token');
    return this.httpClient
      .post<any>(API_URL + 'oauth/token', body, HTTP_OPTIONS)
      .pipe(
        tap((res) => {
          this.tokenService.saveToken(res.access_token);
          this.tokenService.saveRefreshToken(res.refresh_token);
        })
        // catchError(SharedService.handleError)
      );
  }

  getOptions(type: string) {
    return this.httpClient
      .get<any>(API_URL + 'api/user/v1/options/' + type)
      .pipe(
        tap((res) => {})
        // catchError(SharedService.handleError)
      );
  }
  getOptionsByParentCode(parentCode: string, type: string) {
    return this.httpClient
      .get<any>(API_URL + 'api/user/v1/options/' + type + '/' + parentCode)
      .pipe(
        tap((res) => {})
        // catchError(SharedService.handleError)
      );
  }

  public static getAlertNotificationOptions(): any {
    return ALERT_OPTIONS;
  }

  getUserByUsername(username: string, userType: string): Observable<any> {
    if (userType == undefined && userType == null) {
      userType = 'EMP';
    }

    return this.httpClient
      .get<any>(
        API_URL + `api/user/v1/users/find-by-username/${userType}/${username}`,
        {
          headers: {
            useStoreToken: 'true',
          },
        }
      )
      .pipe(
        mergeMap((res) => {
          if (this.isFleetPortal) {
            return this.httpClient
              .get<any>(
                API_URL +
                  `api/user/v1/company/find-by-username/FLEET/${username}`,
                {
                  headers: {
                    useStoreToken: 'true',
                  },
                }
              )
              .pipe(
                mergeMap((response) => {
                  if (response && response.data && response.data.customer_id) {
                    localStorage.setItem(
                      'customerInfo',
                      JSON.stringify(response.data)
                    );
                    this.fleetId = response?.data?.customer_id;
                    this.fleetCompanyId = response?.data?.id;
                  }
                  return of(res);
                })
              );
          } else {
            return of(res);
          }
        })
      );
  }

  sendOTP(mobile: string): Observable<any> {
    return this.httpClient.get<any>(
      API_URL + `api/user/v1/otp/USER/${mobile}/send?otpType=RP`
    );
  }

  verifyOTP(mobile: string, payload = {}): Observable<any> {
    return this.httpClient.post<any>(
      API_URL + `api/user/v1/otp/USER/${mobile}/verify`,
      payload
    );
  }

  getUserRole() {
    const user = JSON.parse(localStorage.getItem('currentuser'));
    const customerInfo = JSON.parse(localStorage.getItem('customerInfo'));
    if (customerInfo) {
      this.fleetId = customerInfo?.customer_id;
      this.fleetCompanyId = customerInfo?.id;
    }
    return user?.roles[0];
  }

  calculateDayHourMin(string) {
    let result = 'N/A';
    if (string) {
      result = '';

      let value = string.split(':');

      if (value.length > 2 && parseInt(value[0]) > 0) {
        result = value[0] > 1 ? `${value[0]}d ` : `${value[0]}d `;
      }

      if (value.length > 1 && parseInt(value[1]) > 0) {
        result += value[1] > 1 ? `${value[1]}h ` : `${value[1]}h `;
      }

      if (value.length > 0) {
        result += value[2] > 1 ? `${value[2]}m` : `${value[2]}m`;
      }
    }

    return result;
  }

  getFormatedDate(datetime, formate = 'YYYY-MM-DD hh:mm:ss', resString = '') {
    if (datetime) {
      return moment(datetime).format(formate);
    } else {
      return resString;
    }
  }

  getFormatedgetPercentage(
    string: any,
    isToFixed = true,
    attachString = '%',
    resString: any = 'N/A'
  ) {
    let result = '';
    if (string && isToFixed) {
      result = `${string?.toFixed(1)}${attachString}`;
    } else if (string) {
      result = `${string}${attachString}`;
    } else {
      result = resString;
    }

    return result;
  }
}
