import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { CommonLayoutComponent } from "./shared/layouts/common-layout/common-layout.component";
import { AuthGuard } from "../_helpers/auth.guards";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { OtpVerificationComponent } from "./otp-verification/otp-verification.component";
import { Role } from "./shared/services/rbac/role.model";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";

const routes: Routes = [
  {
    path: "login",
    component: CommonLayoutComponent,
    children: [{ path: "", component: LoginComponent }],
  },
  {
    path: "forgot-password",
    component: CommonLayoutComponent,
    children: [{ path: "", component: ForgotPasswordComponent }],
  },
  {
    path: "otp-verification",
    component: CommonLayoutComponent,
    children: [{ path: "", component: OtpVerificationComponent }],
  },
  {
    path: "charging-station-locator",
    loadChildren: () =>
      import("./chargin-stations-on-root/chargin-stations-on-root.module").then(
        (m) => m.CharginStationsOnRootModule
      ),
  },
  {
    path: "charging-station-locator-admin",
    data: { roles: [Role.admin, Role.operation, Role.support, Role.user] },
    loadChildren: () =>
      import(
        "./chargin-stations-on-admin/chargin-stations-on-admin.module"
      ).then((m) => m.CharginStationsOnAdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: "cpo",
    data: { roles: [Role.admin, Role.operation, Role.support, Role.user] },
    loadChildren: () => import("./cpo/cpo.module").then((m) => m.CpoModule),
    canActivate: [AuthGuard],
  },
  {
    path: "dashboard",
    data: { roles: [Role.admin, Role.operation, Role.user] },
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: "charginsessions",
    data: { roles: [Role.admin, Role.operation, Role.support, Role.user] },
    loadChildren: () =>
      import("./charginsessions/charginsessions.module").then(
        (m) => m.CharginsessionsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "cdr",
    data: { roles: [Role.admin, Role.operation, Role.support, Role.user] },
    loadChildren: () => import("./cdr/cdr.module").then((m) => m.CdrModule),
    canActivate: [AuthGuard],
  },
  {
    path: "bill",
    data: { roles: [Role.admin, Role.operation, Role.support, Role.user] },
    loadChildren: () => import("./bill/bill.module").then((m) => m.BillModule),
    canActivate: [AuthGuard],
  },
  {
    path: "locations",
    data: { roles: [Role.admin, Role.operation, Role.support, Role.user] },
    loadChildren: () =>
      import("./stations/stations.module").then((m) => m.StationsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "customer",
    data: { roles: [Role.admin, Role.operation, Role.support, Role.user] },
    loadChildren: () =>
      import("./customer/customer.module").then((m) => m.CustomerModule),
    canActivate: [AuthGuard],
  },
  {
    path: "coupon-and-discounts",
    data: { roles: [Role.admin, Role.operation, Role.support, Role.user] },
    loadChildren: () => import("./coupon-and-discounts/coupon-and-discount.module").then((m) => m.CouponAndDiscountModule),
    canActivate: [AuthGuard],
  },
  {
    path: "usermanagement",
    data: { roles: [Role.admin] },
    loadChildren: () =>
      import("./usermanagement/usermanagement.module").then(
        (m) => m.UsermanagementModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "reports",
    data: { roles: [Role.admin, Role.operation, Role.user] },
    loadChildren: () =>
      import("./reports/reports.module").then((m) => m.ReportsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "setup",
    data: { roles: [Role.admin, Role.operation, Role.user] },
    loadChildren: () =>
      import("./setup/setup.module").then((m) => m.SetupModule),
    canActivate: [AuthGuard],
  },
  {
    path: "reset/password",
    data: { roles: [Role.admin, Role.operation, Role.support, Role.user] },
    loadChildren: () =>
      import("./reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "recover",
    data: { roles: [Role.admin, Role.operation, Role.support, Role.user] },
    loadChildren: () =>
      import("./reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: "support",
    data: { roles: [Role.admin, Role.operation, Role.support, Role.user] },
    loadChildren: () =>
      import("./support/support.module").then((m) => m.SupportModule),
    canActivate: [AuthGuard],
  },
  {
    path: "myprofile",
    data: { roles: [Role.admin, Role.operation, Role.support, Role.user] },
    loadChildren: () =>
      import("./myprofile/myprofile.module").then((m) => m.MyprofileModule),
    canActivate: [AuthGuard],
  },
  {
    path: "emsp-integration",
    data: { roles: [Role.admin] },
    loadChildren: () =>
      import("./emsp-integration/emsp-integration.module").then(
        (m) => m.EmspIntegrationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "api-customer",
    data: { roles: [Role.admin, Role.operation, Role.support] },
    loadChildren: () =>
      import("./api-customers/api-customers.module").then(
        (m) => m.ApiCustomersModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'master-vehicle-data',
    data: { roles: [Role.admin] },
    loadChildren: () => import('./master-vehicle-data/master-vehicle-data.module').then(m => m.MasterVehicleDataModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'logs-management', 
    data: { roles: [Role.admin] },
    loadChildren: () => import('./logs-management/logs-management.module').then(m => m.LogsManagementModule),
    canActivate: [AuthGuard]
  },

  { path: "unauthorized", component: UnauthorizedComponent },
  { path: "**", redirectTo: "login" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
