<div class="top-bar">
  <img
    class="app-logo"
    src="../../assets/bg-images/evnnovator-logo-4.webp"
    alt=""
    loading="lazy"
    sizes="(max-width: 580px) 100vw, 580px"
  />
  <div class="user-action-box">
    <button mat-button [matMenuTriggerFor]="userDropdown">
      <span style="text-transform: capitalize">{{ user.username }}</span>
      <mat-icon
        mat-icon-button
        aria-label="Example icon-button with a menu"
        class="material-icons"
        aria-hidden="false"
        aria-label="Menu"
        >arrow_drop_down</mat-icon
      >
    </button>
    <mat-menu #userDropdown="matMenu" style="margin-top: 1rem">
      <button
        mat-menu-item
        [routerLink]="['/myprofile']"
        #myprofile="routerLinkActive"
        routerLinkActive="active"
      >
        <mat-icon class="material-icons">book</mat-icon>
        <span>My Profile</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon
          class="exit-to-app-icon push"
          aria-hidden="false"
          aria-label="Menu"
          >exit_to_app</mat-icon
        >
        <span>Logout</span>
      </button>
    </mat-menu>
  </div>
</div>
<mat-sidenav-container
  autosize
  class="main-container"
  [ngStyle]="{ height: windowInnerHeight }"
>
  <mat-sidenav
    #sidenav
    mode="side"
    [(opened)]="opened"
    (opened)="events.push('open!')"
    (closed)="events.push('close!')"
  >
    <mat-nav-list>
      <a
        mat-list-item
        *ngIf="hasReadAccess('DASHBOARD')"
        [routerLink]="['/dashboard']"
        #rla="routerLinkActive"
        routerLinkActive="active"
      >
        <span class="nav-item-icon">
          <img
            [src]="
              rla.isActive
                ? 'assets/icons/sidenav/dashboard-active.svg'
                : 'assets/icons/sidenav/dashboard.svg'
            "
          />
        </span>
        <span *ngIf="isExpanded">Dashboard </span>
      </a>

      <a
        mat-list-item
        *ngIf="hasReadAccess('CHARGING_STATION')"
        [routerLink]="['/charging-station-locator-admin']"
        #rlast="routerLinkActive"
        routerLinkActive="active"
      >
        <span class="nav-item-icon">
          <img
            [src]="
              rlast.isActive
                ? 'assets/icons/sidenav/marker-active.svg'
                : 'assets/icons/sidenav/marker.svg'
            "
            style="height: 24px; width: 26px"
          />
        </span>
        <span *ngIf="isExpanded">Charging Station Locator</span>
      </a>

      <a
        mat-list-item
        *ngIf="hasReadAccess('CHARGING_STATION')"
        [routerLink]="['/locations']"
        #rlast="routerLinkActive"
        routerLinkActive="active"
      >
        <span class="nav-item-icon">
          <img
            [src]="
              rlast.isActive
                ? 'assets/icons/sidenav/charging-active.svg'
                : 'assets/icons/sidenav/charging.svg'
            "
          />
        </span>
        <span *ngIf="isExpanded">Charging Station</span>
      </a>

      <a
        mat-list-item
        *ngIf="hasReadAccess('CHARGING_SESSION')"
        [routerLink]="['/charginsessions']"
        #rlac="routerLinkActive"
        routerLinkActive="active"
      >
        <span class="nav-item-icon">
          <img
            [src]="
              rlac.isActive
                ? 'assets/icons/sidenav/Total-Session-active.svg'
                : 'assets/icons/sidenav/Total-Session.svg'
            "
          />
        </span>
        <span *ngIf="isExpanded">Charging Sessions</span>
      </a>

      <a
        mat-list-item
        *ngIf="hasReadAccess('BILL_PAYMENT')"
        [routerLink]="['/cdr']"
        #rlac="routerLinkActive"
        routerLinkActive="active"
      >
        <span class="nav-item-icon">
          <img
            [src]="
              rlac.isActive
                ? 'assets/icons/sidenav/charging-sessions-active.svg'
                : 'assets/icons/sidenav/charging-sessions.svg'
            "
          />
        </span>
        <span *ngIf="isExpanded">Charging Data Records</span>
      </a>

      <a
        mat-list-item
        *ngIf="hasReadAccess('BILL_PAYMENT')"
        [routerLink]="['/bill']"
        #rlab="routerLinkActive"
        routerLinkActive="active"
      >
        <span class="nav-item-icon">
          <img
            [src]="
              rlab.isActive
                ? 'assets/icons/sidenav/bill-active.svg'
                : 'assets/icons/sidenav/bill.svg'
            "
          />
        </span>
        <span *ngIf="isExpanded">Bill & Payment</span>
      </a>

      <a
        mat-list-item
        *ngIf="hasReadAccess('CPO_MANAGEMENT')"
        [routerLink]="['/cpo']"
        #rlacp="routerLinkActive"
        routerLinkActive="active"
      >
        <span class="nav-item-icon">
          <img
            [src]="
              rlacp.isActive
                ? 'assets/icons/sidenav/bank-active.svg'
                : 'assets/icons/sidenav/bank.svg'
            "
          />
        </span>
        <span *ngIf="isExpanded">Charge Point Operators</span>
      </a>

      <a
        mat-list-item
        *ngIf="!appCustomer && hasReadAccess('SETUP')"
        [routerLink]="['/emsp-integration']"
        #setup="routerLinkActive"
        routerLinkActive="active"
      >
        <span class="nav-item-icon">
          <img
            style="height: 1.5rem"
            [src]="
              setup.isActive
                ? 'assets/icons/sidenav/transfer-active.svg'
                : 'assets/icons/sidenav/transfer.svg'
            "
          />
        </span>
        <span *ngIf="isExpanded">Interoperability</span>
      </a>

      <a
        mat-list-item
        *ngIf="hasReadAccess('CUSTOMER')"
        [routerLink]="['/customer']"
        #rlacu="routerLinkActive"
        routerLinkActive="active"
      >
        <span class="nav-item-icon">
          <img
            [src]="
              rlacu.isActive
                ? 'assets/icons/sidenav/customer-active.svg'
                : 'assets/icons/sidenav/customer.svg'
            "
          />
        </span>
        <span *ngIf="isExpanded">Customer</span>
      </a>

      <a
        mat-list-item
        *ngIf="hasReadAccess('BILL_PAYMENT')"
        [routerLink]="['/coupon-and-discounts']"
        #rlab="routerLinkActive"
        routerLinkActive="active"
      >
        <span class="nav-item-icon">
          <img
            [src]="
              rlab.isActive
                ? 'assets/icons/sidenav/coupon-active.svg'
                : 'assets/icons/sidenav/coupon.svg'
            "
          />
        </span>
        <span *ngIf="isExpanded">Coupon & Discount</span>
      </a>

      <a
        mat-list-item
        *ngIf="hasReadAccess('REPORTS')"
        [routerLink]="['/reports']"
        #rlare="routerLinkActive"
        routerLinkActive="active"
      >
        <span class="nav-item-icon">
          <img
            [src]="
              rlare.isActive
                ? 'assets/icons/sidenav/reports-active.svg'
                : 'assets/icons/sidenav/reports.svg'
            "
          />
        </span>
        <span *ngIf="isExpanded">Reports & Analytics</span>
      </a>

      <a
        mat-list-item
        *ngIf="hasReadAccess('USER_MANAGEMENT')"
        [routerLink]="['/usermanagement']"
        #rlaus="routerLinkActive"
        routerLinkActive="active"
      >
        <span class="nav-item-icon">
          <img
            [src]="
              rlaus.isActive
                ? 'assets/icons/sidenav/users-active.svg'
                : 'assets/icons/sidenav/users.svg'
            "
          />
        </span>
        <span *ngIf="isExpanded">User Management</span>
      </a>
      <a mat-list-item [routerLink]="['/master-vehicle-data']" #mastervehicledata="routerLinkActive"
        routerLinkActive="active">
        <span class="nav-item-icon">
          <img
            [src]="mastervehicledata.isActive ? 'assets/icons/sidenav/car-active.svg' : 'assets/icons/sidenav/car.svg'" />
        </span>
        <span *ngIf="isExpanded">EV Vehicle Management</span>
      </a>
      <a mat-list-item [routerLink]="['/logs-management']" #logsmanagement="routerLinkActive"
        routerLinkActive="active">
        <span class="nav-item-icon">
          <img
            [src]="logsmanagement.isActive ? 'assets/icons/sidenav/logs-active.svg' : 'assets/icons/sidenav/logs.svg'" />
        </span>
        <span *ngIf="isExpanded">Logs Management</span>
      </a>
      <a
        mat-list-item
        *ngIf="hasReadAccess('SUPPORT')"
        [routerLink]="['/support']"
        #rlase="routerLinkActive"
        routerLinkActive="active"
      >
        <span class="nav-item-icon">
          <img
            [src]="
              rlase.isActive
                ? 'assets/icons/sidenav/support-active.svg'
                : 'assets/icons/sidenav/support.svg'
            "
          />
        </span>
        <span *ngIf="isExpanded">Support</span>
      </a>
      <a
        mat-list-item
        *ngIf="hasReadAccess('SETUP')"
        [routerLink]="['/setup']"
        #setup="routerLinkActive"
        routerLinkActive="active"
      >
        <span class="nav-item-icon">
          <img
            [src]="
              setup.isActive
                ? 'assets/icons/sidenav/settings-active.svg'
                : 'assets/icons/sidenav/settings.svg'
            "
          />
        </span>
        <span *ngIf="isExpanded">Setup</span>
      </a>
    </mat-nav-list>
    <!-- <div (click)="logout()" style="cursor: pointer;" mat-list-item class="logout">
      <span *ngIf="isExpanded">
        <img src="../../../../assets/navmenu/log-out.svg"  style="padding-right: 6px;"/>
        Logout
      </span>
      <div class="logout-icon">
        <mat-icon class="exit-to-app-icon push" aria-hidden="false" aria-label="Menu">exit_to_app</mat-icon>
      </div>
    </div> -->
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="page-content">
      <div class="header">
        <div class="header-left-block">
          <span (click)="updateExpand()">
            <mat-icon
              *ngIf="!isExpanded"
              class="menu-icon"
              aria-hidden="false"
              aria-label="Menu"
              style="font-size: 2.3em"
              >arrow_right</mat-icon
            >
            <mat-icon
              *ngIf="isExpanded"
              class="menu-icon"
              aria-hidden="false"
              aria-label="Menu"
              style="font-size: 2.3em"
              >arrow_left</mat-icon
            >
          </span>
        </div>
        <!-- <div class="page-heading">{{ appTitle }}</div> -->
        <div class="header-right-block"></div>
      </div>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
