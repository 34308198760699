import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { LoginComponent } from './login/login.component';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BasicAuthInterceptor } from 'src/_helpers/basic-auth.interceptor';
import { ErrorInterceptor } from 'src/_helpers/error.interceptors';
import { MatDialogModule } from '@angular/material/dialog';
import { AlertModule } from './_alert/alert.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { OtpVerificationComponent } from './otp-verification/otp-verification.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ConfirmationDialogComponent } from './_common/confirmation-dialog/confirmation-dialog.component';
// import { CharginStationsOnRootComponent } from "./chargin-stations-on-root/chargin-stations-on-root.component";
// import { CharginStationsOnRootModule } from "./chargin-stations-on-root/chargin-stations-on-root.module";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    // CharginStationsOnRootComponent,
    ForgotPasswordComponent,
    OtpVerificationComponent,
    UnauthorizedComponent,
    ConfirmationDialogComponent,  
  ],
  imports: [
    // CharginStationsOnRootModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatGridListModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatFormFieldModule,
    HttpClientModule,
    MatDialogModule,
    MatToolbarModule,
    AlertModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    Title,
    // MatSidenav,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
