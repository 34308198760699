import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from '@app/shared/services/shared.service';
import { StoreService } from '@app/shared/services/store.service';
import { ToasterService } from '@app/shared/services/toaster.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  resetForm: FormGroup;
  errorData = {
    display: true,
    message: '',
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private storeService: StoreService,
    private sharedService: SharedService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    this.initForms();
  }

  initForms() {
    this.resetForm = this.formBuilder.group({
      mobile: ['', Validators.required],
    });
  }

  public noWhitespaceValidator() {
    if (this.resetForm.value.mobile) {
      return (this.resetForm.value.mobile || '').trim().length ? false : true;
    } else {
      return false;
    }
  }

  onFormSubmit() {
    if (this.resetForm.invalid) {
      return;
    }

    this.sharedService.sendOTP(this.resetForm.value.mobile).subscribe(
      (response) => {
        if (response.status_code == 1000) {
          this.storeService.set('mobile', this.resetForm.value.mobile);
          this.router.navigate(['/otp-verification']);
        }
      },
      (error) => {
        if (error == 'User not found') {
          return this.toaster.open('The mobile number is not registered.');
        }

        console.log(error);
        this.toaster.open('Uh Oh! Something went wrong. Please try again.');
      }
    );
  }
}
